import React from "react"
import { Link } from "gatsby"

import Img from "gatsby-image"
import { usePostsMetaData } from "../hooks/use-posts-metadata"

import "./postspreview.css"

const PostsPreview = () => {
  const data = usePostsMetaData()
  console.log("data")
  console.log(data)
  return (
    <div className="posts-preview">
      {data.allWpPost.nodes.map(node => (
        <>
          <div key={node.id} className="post-card">
            <Link to={node.slug}>
              <div className="post-image">
                <Img
                  fluid={
                    node.featuredImage.node.localFile.childImageSharp.fluid
                  }
                  alt={node.title}
                />
              </div>
              <div className="post-content">
                <div className="post-title">
                  <Link to={node.slug}>{node.title}</Link>
                </div>
                <div className="post-metadata">
                  <span>{node.author.node.name}</span>
                  <span>{node.date}</span>
                </div>
              </div>
            </Link>
          </div>
        </>
      ))}
    </div>
  )
}

export default PostsPreview
