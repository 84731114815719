import React from "react"

import Layout from "../components/layout"

import SEO from "../components/seo"
import PostsPreview from "../components/postspreview"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />

      <div className="hero-text">
        <h1>
          Hello, I’m Senthil Ganesh{" "}
          <span aria-hidden="true" className="wave">
            👋
          </span>
        </h1>
        <h1>
          I
          <span style={{ position: "absolute" }}>
            <svg width="48" height="48" viewBox="0 0 200 200">
              <g transform="translate(100 100)">
                <path
                  transform="translate(-50 -50)"
                  fill="#d44c46"
                  d="M92.71,7.27L92.71,7.27c-9.71-9.69-25.46-9.69-35.18,0L50,14.79l-7.54-7.52C32.75-2.42,17-2.42,7.29,7.27v0 c-9.71,9.69-9.71,25.41,0,35.1L50,85l42.71-42.63C102.43,32.68,102.43,16.96,92.71,7.27z"
                ></path>
                <animateTransform
                  attributeName="transform"
                  type="scale"
                  values="1; 1.5; 1.25; 1.5; 1.5; 1;"
                  dur="1s"
                  repeatCount="indefinite"
                  additive="sum"
                ></animateTransform>
              </g>
            </svg>
          </span>
          <span style={{ marginLeft: "50px" }}>
            web development and travelling.
          </span>
        </h1>
      </div>
      <PostsPreview data={data} />
      <div style={{ margin: "25px 0" }}>
        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-8676170233811690"
          data-ad-slot="4567248565"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      </div>
    </Layout>
  )
}

export default IndexPage
