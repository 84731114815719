import { useStaticQuery, graphql } from "gatsby"
export const usePostsMetaData = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      allWpPost(
        filter: { status: { eq: "publish" } }
        sort: { fields: [date] }
      ) {
        nodes {
          id
          status
          slug
          title
          uri
          excerpt
          date(formatString: "MMM DD, YYYY")
          author {
            node {
              name
              slug
            }
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 500, quality: 70) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return pageQuery
}
